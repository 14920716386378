<style lang="less" scoped>
/deep/ .ant-form-item-label {
  width: 70px;
  text-align: left;

}
/deep/ .ant-upload {
  line-height: 100%;
}

</style>
<template>
  <a-card :bordered="false" style="width: 100%">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="8">
          <a-col :md="6" :sm="24">
            <a-form-item label="车牌号">
              <a-input v-model="queryParam.vno" placeholder="" />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="使用状态">
              <a-select v-model="queryParam.status" placeholder="请选择" default-value="0">
                <a-select-option value="-1">全部</a-select-option>
                <a-select-option value="1">正常</a-select-option>
                <a-select-option value="0">禁用</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="(!advanced && 6) || 24" :sm="24">
            <span
              class="table-page-search-submitButtons"
              :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
            >
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
              <a-button style="margin-left: 8px" @click="() => (queryParam = {})">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd()" v-action:but_vehicle_add>新建</a-button>
    </div>

    <s-table
      ref="table"
      size="default"
      :rowKey="(record) => record.id"
      :columns="columns"
      :pagination="pagination"
      :data="loadData"
      :pageSize="50"
      :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      showPagination="auto"
      :scroll="{ x: 'max-content', y: scrollHeight }"
    >
      <!--      <span slot="serial" slot-scope="text, record, index">-->
      <!--        {{ index + 1 }}-->
      <!--      </span>-->
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleEdit(record)" v-action:but_vehicle_edit>编辑&ensp;|&ensp;</a>
          <a @click="updateStatus(record)" v-action:but_vehicle_disable>{{ record.status == 1 ? '禁用' : '启用' }}</a>
          <!--如果需要权限 v-action:roleBD -->
        </template>
      </span>
    </s-table>

    <a-modal
      key="addVehicle"
      title="新增/编辑"
      :width="700"
      :visible="visible_add"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      :closable="true"
      @cancel="addHandleCancel"
      :centered="true"
      :mask="true"
      :footer="null"
      :maskClosable="false"
      :body-style="{ height: 'auto' }"
    >
      <div>
        <a-form @submit="handleSubmit" :form="form">
          <table style="width: 100%">
            <tr>
              <td style="width: 50%" align="center">
                <a-form-item
                  label="车牌号"
                  :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
                  :wrapperCol="{ lg: { span: 15 }, sm: { span: 17 } }"
                >
                  <a-input v-decorator="['vno', { rules: [{ required: true, message: '请输入车牌号' }] }]" name="vno" />
                </a-form-item>
              </td>
              <td style="width: 50%" align="center">
                <a-form-item
                  label="挂车号"
                  :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
                  :wrapperCol="{ lg: { span: 15 }, sm: { span: 17 } }"
                >
                  <a-input name="trailer" />
                </a-form-item>
              </td>
            </tr>

            <tr>
              <td style="width: 50%" align="center">
                <a-form-item
                  label="车辆类型"
                  :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
                  :wrapperCol="{ lg: { span: 15 }, sm: { span: 17 } }"
                >
                  <a-select placeholder="请选择车辆类型" @change="handleChange2" v-decorator="['type']">
                    <a-select-option value="1">4米2</a-select-option>
                    <a-select-option value="2">7米6</a-select-option>
                    <a-select-option value="3">9米6</a-select-option>
                    <a-select-option value="4">15米</a-select-option>
                  </a-select>
                </a-form-item>
              </td>
              <td style="width: 50%" align="center">
                <a-form-item
                  label="归属性质"
                  :labelCol="{ lg: { span: 9 }, sm: { span: 9 } }"
                  :wrapperCol="{ lg: { span: 15 }, sm: { span: 13 } }"
                >
                  <a-select placeholder="请选择车辆归属性质" @change="handleChange3" v-decorator="['ascription']">
                    <a-select-option value="1">自有</a-select-option>
                    <a-select-option value="2">外协</a-select-option>
                  </a-select>
                </a-form-item>
              </td>
            </tr>

            <tr>
              <td style="width: 50%" align="center">
                <a-form-item
                  label="能源类型"
                  :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
                  :wrapperCol="{ lg: { span: 15 }, sm: { span: 17 } }"
                >
                  <a-select placeholder="请选择能源类型" @change="handleChange4" v-decorator="['energy']">
                    <a-select-option value="柴油">柴油</a-select-option>
                    <a-select-option value="汽油">汽油</a-select-option>
                    <a-select-option value="纯电">纯电</a-select-option>
                    <a-select-option value="混动">混动</a-select-option>
                  </a-select>
                </a-form-item>
              </td>
              <td style="width: 50%" align="center">
                <a-form-item
                  label="车主"
                  :labelCol="{ lg: { span: 9 }, sm: { span: 9 } }"
                  :wrapperCol="{ lg: { span: 15 }, sm: { span: 13 } }"
                >
                  <a-input v-decorator="['owner', { rules: [{ required: false, message: '请输入' }] }]" name="owner" />
                </a-form-item>
              </td>
            </tr>

            <tr>
              <td style="width: 50%" align="center">
                <a-form-item
                  label="行驶证照片"
                  :labelCol="{ lg: { span: 8 }, sm: { span: 8 } }"
                  :wrapperCol="{ lg: { span: 14 }, sm: { span: 14 } }"
                >
                  <a-upload
                    name="file"
                    :max-count="1"
                    list-type="picture-card"
                    class="avatar-uploader"
                    :show-upload-list="false"
                    :customRequest="customRequest"
                  >
                    <img id="vehicleLicensePic" src="" width="100px" height="100px" alt="avatar" />
                  </a-upload>
                </a-form-item>
              </td>

              <td style="width: 50%" align="center">
                <a-form-item
                  label="车辆照片"
                  :labelCol="{ lg: { span: 8 }, sm: { span: 8 } }"
                  :wrapperCol="{ lg: { span: 14 }, sm: { span: 14 } }"
                >
                  <a-upload
                    name="file"
                    :max-count="1"
                    list-type="picture-card"
                    class="avatar-uploader"
                    :show-upload-list="false"
                    :customRequest="customRequest2"
                    style="line-height: 100%"
                  >
                    <img id="vehiclePic" src="" width="100px" height="100px" alt="avatar" />
                  </a-upload>
                </a-form-item>
              </td>
            </tr>
          </table>

          <a-form-item :wrapperCol="{ span: 24 }" class="form-submit">
            <a-button style="margin-left: 400px; margin-right: 8px" @click="addHandleCancel">取消</a-button>
            <a-button htmlType="submit" type="primary">提交</a-button>
          </a-form-item>
        </a-form>
      </div>
    </a-modal>
  </a-card>
</template>

<script>
import moment from 'moment'
import { STable } from '@/components'
import { findPageVehicle, updateVehicle, saveVehicle, deleteVehicle } from '@/api/manage'
import Vue from 'vue'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { put } from '@/utils/upload'
import AuthImg from '@/components/AuthImg/AuthImg'

export default {
  name: 'TableList',
  components: {
    STable,
    AuthImg,
  },
  data() {
    return {
      mdl: {},
      openKeys: ['1'],
      // 高级搜索 展开/关闭
      advanced: false,
      pagination: { pageSizeOptions: ['50', '100', '200', '500'] },
      treeData: [],
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '车牌号',
          key: 'vno',
          align: 'center',
          width: '260px',
          dataIndex: 'vno',
        },
        {
          title: '车主',
          key: 'owner',
          align: 'center',
          width: '200px',
          dataIndex: 'owner',
        },
        {
          title: '车辆类型',
          key: 'type',
          dataIndex: 'type',
          width: '220px',
          align: 'center',
          customRender: (text) => {
            let ret = ''
            if (text == 1) {
              ret = '4米2'
            } else if (text == 2) {
              ret = '7米6'
            } else if (text == 3) {
              ret = '9米6'
            } else if (text == 4) {
              ret = '15米'
            }
            return ret
          },
        },
        {
          title: '能源类型',
          width: '220px',
          key: 'energy',
          align: 'center',
          dataIndex: 'energy',
        },
        {
          title: '归属性质',
          key: 'ascription',
          dataIndex: 'ascription',
          width: '200px',
          align: 'center',
          customRender: (text) => {
            let ret = ''
            if (text == 1) {
              ret = '自有'
            } else {
              ret = '外协'
            }
            return ret
          },
        },
        {
          title: '状态',
          key: 'status',
          dataIndex: 'status',
          width: '200px',
          align: 'center',
          customRender: (text) => {
            let ret = ''
            if (text == 1) {
              ret = '正常'
            } else {
              ret = '禁用'
            }
            return ret
          },
        },
        {
          title: ''
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '220px',
          align: 'center',
          fixed: 'right',
          scopedSlots: { customRender: 'action' },
        },
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: (parameter) => {
        console.log('loadData.parameter', parameter)
        let param = {}
        param = Object.assign(parameter, this.queryParam)
        return findPageVehicle(param).then((res) => {
          return res.result
        })
      },
      selectedRowKeys: [],
      selectedRows: [],
      options: {
        alert: {
          show: true,
          clear: () => {
            this.selectedRowKeys = []
          },
        },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange,
        },
      },
      optionAlertShow: false,
      confirmLoading: false,
      visible_add: false,
      form: this.$form.createForm(this),
      tabObj: {}, //当前对象，编辑是用到
      addFlag: true, //true新增false编辑
      expandedKeys: [], //已绑定的keys
      submitKeys: [], //提交保存的key,包括半选中和选中
      updateVo: {},
      scrollHeight: 0,
    }
  },
  created() {
    this.tableOption()
    this.scrollHeight = document.body.scrollHeight - 400
  },
  filters: {},
  methods: {
    tableOption() {
      if (!this.optionAlertShow) {
        this.options = {
          alert: {
            show: true,
            clear: () => {
              this.selectedRowKeys = []
            },
          },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange,
          },
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null,
        }
        this.optionAlertShow = false
      }
    },
    handleEdit(record) {
      let that = this
      this.visible_add = true
      this.tabObj = record
      this.addFlag = false
      if(record.ascription==null){
        record.ascription = ""
      }
      if(record.energy==null){
        record.energy = ""
      }
      if(record.type==null){
        record.type = ""
      }
      this.$nextTick(() => {
        this.form.setFieldsValue({
          vno: record.vno,
          trailer: record.trailer,
          type: record.type + '',
          energy: record.energy,
          ascription: record.ascription + '',
          status: record.status,
          vehicleLicensePic: record.vehicleLicensePic,
          owner: record.owner,
        })
        that.getimgblob('/service_provider/upload/getPic?fileName=' + record.vehicleLicensePic, 'vehicleLicensePic')
        that.getimgblob('/service_provider/upload/getPic?fileName=' + record.vehiclePic, 'vehiclePic')
      })
    },
    handleDel(record) {},
    updateStatus(record) {
      let targetstatus = 1
      if (record.status == 1) {
        targetstatus = 0
      }
      updateVehicle({ id: record.id, status: targetstatus })
        .then((res) => {
          //提交成功
          this.$message.success('更新成功')
          this.$refs.table.refresh()
        })
        .catch((e) => {
          this.$message.error('更新失败')
        })
    },
    handleOk() {},

    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    resetSearchForm() {
      this.queryParam = {
        date: moment(new Date()),
      }
    },
    addHandleCancel() {
      this.visible_add = false
    },
    handleAdd() {
      this.visible_add = true
      this.tabObj = {}
      this.updateVo = {}
      this.addFlag = true
    },
    handleChange2(value) {
      this.updateVo.type = value
    },
    handleChange3(value) {
      this.updateVo.ascription = value
    },
    handleChange4(value) {
      this.updateVo.energy = value
    },
    handleSubmit(e) {
      e.preventDefault()
      let that = this
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values)
          if (that.addFlag) {
            if (that.updateVo.vehicleLicensePic != null) {
              values.vehicleLicensePic = that.updateVo.vehicleLicensePic
            }
            if (that.updateVo.vehiclePic != null) {
              values.vehiclePic = that.updateVo.vehiclePic
            }

            saveVehicle(values)
              .then((res) => {
                //提交成功
                this.$message.success('保存成功')
                that.visible_add = false
                this.$refs.table.refresh()
              })
              .catch((e) => {
                this.$message.error('保存失败')
              })
          } else {
            //修改角色
            values.id = this.tabObj.id
            if (that.updateVo.vehicleLicensePic != null) {
              values.vehicleLicensePic = that.updateVo.vehicleLicensePic
            }
            if (that.updateVo.vehiclePic != null) {
              values.vehiclePic = that.updateVo.vehiclePic
            }
            updateVehicle(values)
              .then((res) => {
                //提交成功
                this.$message.success('保存成功')
                that.visible_add = false
                this.$refs.table.refresh()
              })
              .catch((e) => {
                this.$message.error('保存失败')
              })
          }
        }
      })
    },
    iscandel(record) {
      if (record.type == 1) {
        return false
      } else {
        return true
      }
    },
    customRequest(action) {
      let that = this
      const file = action.file
      put(
        new Date().valueOf() + parseInt(Math.random() * 10000) + file.name.substring(file.name.lastIndexOf('.')),
        file
      )
        .then((result) => {
          // this.loading = false
          // console.info("-----")
          let fileName = result.name
          this.updateVo.vehicleLicensePic = fileName
          let url = '/service_provider/upload/getPic?fileName=' + fileName
          that.getimgblob(url, 'vehicleLicensePic')
        })
        .catch((e) => {
          console.info(e)
        })
    },
    customRequest2(action) {
      let that = this
      const file = action.file
      put(
        new Date().valueOf() + parseInt(Math.random() * 10000) + file.name.substring(file.name.lastIndexOf('.')),
        file
      )
        .then((result) => {
          // this.loading = false
          // console.info("-----")
          let fileName = result.name
          this.updateVo.vehiclePic = fileName
          let url = '/service_provider/upload/getPic?fileName=' + fileName
          that.getimgblob(url, 'vehiclePic')
        })
        .catch((e) => {
          console.info(e)
        })
    },
    async getimgblob(url, id) {
      let token = Vue.ls.get(ACCESS_TOKEN)
      let request = new XMLHttpRequest()
      request.timeout = 9000
      request.responseType = 'blob'
      request.open('get', url, true)
      // 携带请求头
      request.setRequestHeader('token', token)
      request.onreadystatechange = (e) => {
        if (request.readyState === XMLHttpRequest.DONE && request.status === 200) {
          document.getElementById(id).src = URL.createObjectURL(request.response)
        }
      }
      request.send(null)
    },
  },
  computed: {
    headers() {
      let token = Vue.ls.get(ACCESS_TOKEN)
      return {
        token: token,
      }
    },
  },
}
</script>
<style lang="less" scoped>
/deep/ .ant-form-item-control-wrapper{
  width: 78%;
}
/deep/  .form-submit {
    margin-bottom: 0;
  .ant-form-item-control-wrapper{
    width: 100% !important;
    text-align: right;
  }
}
.table-operator {
  margin-bottom: 10px;
}
</style>